import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const UtbL1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="579.5px"
        height="1379px"
        viewBox="0 0 579.5 1379"
      >
        <GrainPattern id={id} />
        <path
          fill={`url(#${id})`}
          d="M395.8,273.7c-90.6-64.6-193.1-112.8-280.2-182C77.2,61.1,42.8,25.5,0,0v1379c18-11.4,35.2-23.8,52.6-35.2
	c36.2-23.7,72.6-47.4,105.1-74.8c30.5-25.7,57.8-55.4,73.8-91.3c15-33.4,18.5-70.9,21.6-108.7c3.3-39.9,7.2-79.6,26.6-113.2
	c22.3-38.5,57.2-68.9,87-102.1c57-63.4,109.1-130.5,160.8-197.7c42.3-54.9,69.7-111,38.3-190.3C535.9,390.1,469.2,326,395.8,273.7z"
        />
      </svg>
    )
  }
)

export default UtbL1
