import React, { FunctionComponent } from "react"
import styles from "./utbildningar.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { graphql } from "gatsby"
import Image, { FluidObject } from "gatsby-image"
import cx from "classnames"
import ImageTextRow from "../components/image-text-row"
import Splash, { Subtitle, Title, SplashColor } from "../components/splash"
import SectionTitle, {
  Title as STitle,
  Subitle as SSubtitle,
} from "../components/section-title"
import LinkButton from "../components/link-button"
import UtbL1 from "../components/blobs/utb-l-1"
import UtbR1 from "../components/blobs/utb-r-1"
import UtbT1 from "../components/blobs/utb-t-1"
import useParallax, { makeTransformY } from "../hooks/use-parallax"
import { UtbildningarQuery } from "../generated/graphql"
import { oc } from "ts-optchain"
import renderAst from "../lib/render-ast"

const colors: SplashColor[] = ["purple", "pink", "orange"]

const UtbildningarPage: FunctionComponent<{ data: UtbildningarQuery }> = ({
  data: { page },
}) => {
  const refBlobT = useParallax<SVGSVGElement>(null, makeTransformY(-1, "-50%"))
  const refBlobL = useParallax<SVGSVGElement>(null, makeTransformY(1))
  const refBlobR = useParallax<SVGSVGElement>(null, makeTransformY(1))

  return (
    <Layout>
      <SEO title={oc(page).title("")} />
      <UtbT1 className={styles.blobT} ref={refBlobT} />
      <Hero backgroundColor="red">
        <h1>{oc(page).heroTitle()}</h1>
        {renderAst(oc(page).heroText.childMarkdownRemark.htmlAst())}
      </Hero>
      <UtbL1 className={styles.blobL} ref={refBlobL} />
      <UtbR1 className={styles.blobR} ref={refBlobR} />
      <div className={styles.main}>
        <ImageTextRow>
          <div className={styles.textItem}>
            {renderAst(oc(page).text.childMarkdownRemark.htmlAst())}
          </div>
          <Image
            className={styles.fluid}
            fluid={
              oc(page).image.localFile.childImageSharp.fluid() as FluidObject
            }
            alt=""
          />
        </ImageTextRow>

        <SectionTitle>
          <STitle>{oc(page).phaseTitle()}</STitle>
          <SSubtitle>
            {renderAst(oc(page).phaseIntro.childMarkdownRemark.htmlAst())}
          </SSubtitle>
        </SectionTitle>
        <div className={cx("container", styles.educationGrid)}>
          {oc(page)
            .phases([])
            .map((phase, i) => {
              if (!phase) {
                return null
              }

              return (
                <div key={i}>
                  <Splash inline color={colors[i % colors.length]}>
                    <Title>{phase.title}</Title>
                    {renderAst(
                      oc(phase).subtitle.childMarkdownRemark.htmlAst(),
                      { p: Subtitle }
                    )}
                  </Splash>
                  <ul>
                    {oc(phase)
                      .items([])
                      .map((item, i) => (
                        <li key={i}>{item}</li>
                      ))}
                  </ul>
                </div>
              )
            })}
        </div>
        <div className={styles.pdfDownload}>
          <LinkButton center to={oc(page).downloadPdf.file.url("")}>
            {oc(page).downloadPdf.title("")}
          </LinkButton>
        </div>

        {oc(page)
          .textAndImageSections([])
          .map((node, i) => {
            if (!node) {
              return
            }

            const { title, text, image } = node

            return (
              <ImageTextRow rev>
                <div className={styles.textItem}>
                  <h2>{title}</h2>
                  {renderAst(oc(text).childMarkdownRemark.htmlAst())}
                </div>
                <Image
                  className={styles.image}
                  fluid={
                    oc(image).localFile.childImageSharp.fluid() as FluidObject
                  }
                  alt=""
                />
              </ImageTextRow>
            )
          })}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query Utbildningar($id: String!) {
    page: contentfulArbetstraning(id: { eq: $id }) {
      title
      heroTitle
      heroText {
        childMarkdownRemark {
          htmlAst
        }
      }
      text {
        childMarkdownRemark {
          htmlAst
        }
      }
      image {
        localFile {
          childImageSharp {
            fluid(maxWidth: 565) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      phaseTitle
      phaseIntro {
        childMarkdownRemark {
          htmlAst
        }
      }
      phases {
        title
        subtitle {
          childMarkdownRemark {
            htmlAst
          }
        }
        items
      }
      downloadPdf {
        title
        file {
          url
          fileName
          contentType
        }
      }
      textAndImageSections {
        text {
          childMarkdownRemark {
            htmlAst
          }
        }
        title
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 565) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`

export default UtbildningarPage
