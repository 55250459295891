import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const UtbR1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()
    return (
      <svg
        ref={ref}
        {...props}
        width="414.7px"
        height="1066.4px"
        viewBox="0 0 414.7 1066.4"
      >
        <GrainPattern id={id} x="100%" />
        <path
          fill={`url(#${id})`}
          d="M414.7,0c-22.1,11-43.9,22.8-65,36.3c-45.1,28.7-86.8,62.4-127.8,96.8c-8.3,7-16.7,14-24.9,20.8
	c-6.6,5.6-13,11-19.3,16.4c-5.7,4.9-11.1,9.6-16.5,14.3c-6.5,5.8-12.9,11.4-19.1,17.2c-8.2,7.6-13.8,14-21.7,21.6
	c-3.6,3.6-9.5,8.2-13,11.7c-6.3,6.5-12.7,13.2-18.8,20c-4.1,4.6-8.2,9.2-12,13.7c-3.3,4-15.8,15.2-16.1,13.9
	c-10.6,13.3-14,24.6-22.7,38.6c-6.6,10.5-12.6,21.4-18,32.6c-4.2,8.8-7.9,17.8-10.9,27.1c-3.9,11.5-6.5,23.3-7.9,35.3
	c-0.5,4.7-0.8,9.2-0.9,13.8c-0.1,7.2,0.4,14.5,1.6,21.6c0.8,5.3,2.2,10.5,4,15.6c2.7,7.2,6.2,14,10.7,20.2
	c8.4,11.8,23.3,18.3,34.5,27.5c3.1,2.2,9.4,14.2,16.3,19.7c4.4,3.5,11.9,9.1,15.5,12.6c6.5,6.4,8.7,10.9,13.6,17.7
	c8.4,11.7,15.3,24.5,20.5,38c2.2,5.8,4.2,11.6,5.9,17.5c1.4,4.8,2.6,9.5,3.6,14.3c2,8.9,3.7,18,5.1,26.8c1.9,11.9-0.3,26.1,0.1,32.3
	c1.9,16.3,9.9,33.5,11.9,49.4c1,8.1,0.2,17.8,1.4,25.4c2.3,15.2,5.5,30.2,9.6,44.9c2.2,7.8,4.7,15.3,7.5,22.4
	c3.1,7.9,6.7,15.7,10.7,23.2c5.6,10.3,12,20.2,19.2,29.6c6.9,9.1,14.4,17.7,22.4,25.9c5,5.1,10,9.9,15.1,14.5
	c12.3,11.1,31.6,22.1,44.8,31.7c6.5,7.3,21.4,18.5,35.6,27.7c10.6,6.9,21.1,13.6,31.4,20.3c3.1,2,6.3,4.1,9.1,5.9
	c6.5,4.2,12.7,8.3,18.7,12.3c14.5,9.8,28.4,26.5,41.5,37c1.1,0.6,2.6,1.2,4.3,2V0z"
        />
      </svg>
    )
  }
)

export default UtbR1
