import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import uuidv4 from "uuid/v4"
import GrainPattern from "./grain-pattern"

const UtbT1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()
    return (
      <svg
        ref={ref}
        {...props}
        width="1254.8px"
        height="433.1px"
        viewBox="0 0 1254.8 433.1"
      >
        <GrainPattern id={id} x="50%" />
        <path
          fill={`url(#${id})`}
          d="M1254.8,0H0c41.9,25.9,85.9,49,129.5,72.2c47.1,25,94.6,49.5,143.5,69.9c47.8,20,95.9,34.4,143.8,48.5
    c50,14.8,104.5,28.6,153.7,53c47.1,23.3,65.5,59.5,100.3,90.3c26.3,23.3,57.6,44.3,90.7,60.9c65.2,32.8,135.3,47.6,184.7,32.3
    c33.9-10.5,60.5-29.3,88.5-45.1c30.9-17.5,62.1-35,88.9-55.8c25.2-19.5,46.7-42.5,56.6-71.3c9.3-26.8,6.9-57.7,4.1-88.8
    c-2.9-32.9-5.3-65.5,8.2-92.2C1206.8,45.6,1232.4,23.5,1254.8,0z"
        />
      </svg>
    )
  }
)

export default UtbT1
